import { BusinessInfo } from '@wix/bookings-uou-types/dist/src';
import { BookingPolicyDto } from '../../types/shared-types';

export interface PolicySectionViewModel {
  cancellationPolicy?: string;
}
export const policySectionViewModelFactory = (
  bookingPolicyDto: BookingPolicyDto,
  businessInfo: BusinessInfo,
): PolicySectionViewModel => {
  return {
    cancellationPolicy: bookingPolicyDto.cancellationPolicy,
  };
};
