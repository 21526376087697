import {
  CatalogServiceDto,
  MediaDto,
  PaymentType as PaymentTypeV1,
  PricingPlanInfo,
  ServiceLocation,
  ServiceLocationType,
  ServicePaymentDto,
  ServiceScheduleHeaderDto,
  ServiceType as ServiceTypeV1,
} from '@wix/bookings-uou-types';
import {
  Location,
  LocationType,
  Payment,
  RateType,
  Schedule,
  Service,
  ServiceType,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { GetServiceResponse } from '@wix/ambassador-services-catalog-server/types';

export const mapServiceTypeV1ToV2 = (
  serviceType: ServiceTypeV1,
): ServiceType => {
  switch (serviceType) {
    case ServiceTypeV1.INDIVIDUAL:
      return ServiceType.APPOINTMENT;
    case ServiceTypeV1.GROUP:
      return ServiceType.CLASS;
    case ServiceTypeV1.COURSE:
      return ServiceType.COURSE;
  }
};

const mapPaymentRateType = (payment: ServicePaymentDto): RateType => {
  if (payment.priceText) {
    return RateType.CUSTOM;
  } else if (payment.isFree || !payment.price) {
    return RateType.NO_FEE;
  } else if (payment.isVariedPricing) {
    return RateType.VARIED;
  } else {
    return RateType.FIXED;
  }
};

export const mapPaymentToPaymentV2 = (
  payment: ServicePaymentDto,
  pricingPlanInfo: PricingPlanInfo,
): Payment => {
  const rateType = mapPaymentRateType(payment);
  const paymentV2: Payment = {
    rateType,
    pricingPlanIds: pricingPlanInfo.pricingPlans.map((plan) => plan.id),
  };
  if (rateType === RateType.FIXED) {
    paymentV2.fixed = {
      price: { value: `${payment.price}`, currency: payment.currency },
    };
  } else if (RateType.CUSTOM === rateType) {
    paymentV2.custom = {
      description: payment.priceText,
    };
  } else if (RateType.VARIED === rateType) {
    paymentV2.varied = {
      defaultPrice: { value: `${payment.price}`, currency: payment.currency },
      minPrice: {
        value: `${payment.minPrice?.price}`,
        currency: payment.minPrice?.currency,
      },
      maxPrice: {
        value: `${payment.maxPrice?.price}`,
        currency: payment.maxPrice?.currency,
      },
    };
  }
  paymentV2.options = {
    online: [PaymentTypeV1.ONLINE || PaymentTypeV1.BOTH].includes(
      payment.paymentType,
    ),
    inPerson: [PaymentTypeV1.OFFLINE || PaymentTypeV1.BOTH].includes(
      payment.paymentType,
    ),
  };
  return paymentV2;
};

const mapLocationType = (location: ServiceLocationType): LocationType => {
  switch (location) {
    case ServiceLocationType.OWNER_BUSINESS:
      return LocationType.BUSINESS;
    case ServiceLocationType.OWNER_CUSTOM:
      return LocationType.CUSTOM;
    case ServiceLocationType.CUSTOM:
      return LocationType.CUSTOMER;
    case ServiceLocationType.CLIENT_PLACE:
      return LocationType.CUSTOMER;
    default:
      return LocationType.BUSINESS;
  }
};
export const mapLocationsToLocationV2 = (
  locations?: ServiceLocation[],
): Location[] | undefined => {
  return locations?.map((location: ServiceLocation) => {
    return {
      type: mapLocationType(location.type),
      business: location.businessLocation && {
        id: location.businessLocation?.id,
        name: location.businessLocation?.name,
        default: location.businessLocation?.default,
        address: location.businessLocation?.address,
        phone: location.businessLocation?.phone,
        email: location.businessLocation?.email,
      },
      custom: {
        address: {
          formattedAddress: location.address || location.locationText,
        },
      },
      calculatedAddress: {
        formattedAddress: location.address || location.locationText,
      },
    };
  });
};

const mapScheduleToScheduleV2 = (
  scheduleHeader: ServiceScheduleHeaderDto,
  serviceResponse?: GetServiceResponse,
): Schedule => {
  const schedule = serviceResponse?.schedules?.find(
    (serviceSchedule) => serviceSchedule.status === 'CREATED',
  );
  return {
    firstSessionStart: schedule?.firstSessionStart
      ? new Date(schedule?.firstSessionStart)
      : undefined,
    lastSessionEnd: schedule?.lastSessionEnd
      ? new Date(schedule?.lastSessionEnd)
      : undefined,
    availabilityConstraints: {
      sessionDurations: scheduleHeader.durationsInMinutes || [],
    },
    id: schedule?.id,
  };
};
const mapServiceOnlineBookingToV2 = (
  service: CatalogServiceDto,
): Service['onlineBooking'] => {
  return {
    requireManualApproval: service.schedulePolicy.isPendingApprovalFlow,
    enabled: !service.schedulePolicy.displayOnlyNoBookFlow,
  };
};

const mapServiceMedia = (media: MediaDto): Service['media'] => {
  return {
    items: media?.gallery?.map((image) => ({
      image: {
        url: image.relativeUri,
        width: image.width,
        height: image.height,
        altText: image.altText,
      },
    })),
    coverMedia: media?.coverMedia
      ? {
          image: {
            url: media?.coverMedia?.relativeUri,
            width: media?.coverMedia?.width,
            height: media?.coverMedia?.height,
            altText: media?.coverMedia?.altText,
          },
        }
      : undefined,
    mainMedia: media?.mainMedia
      ? {
          image: {
            url: media?.mainMedia?.relativeUri,
            width: media?.mainMedia?.width,
            height: media?.mainMedia?.height,
          },
        }
      : undefined,
  };
};

function mapServicesUrls(serviceResponse: GetServiceResponse): Service['urls'] {
  const servicePageUrls = serviceResponse?.urls?.servicePageUrl;
  const bookingPageUrls = serviceResponse?.urls?.bookingPageUrl;
  const calendarPageUrls = serviceResponse?.urls?.calendarPageUrl;
  let servicePage = {};
  let bookingPage = {};
  let calendarPage = {};
  if (servicePageUrls) {
    servicePage = {
      servicePage: {
        relativePath: servicePageUrls.path,
        url: servicePageUrls.base + servicePageUrls.path,
      },
    };
  }
  if (bookingPageUrls) {
    bookingPage = {
      bookingPage: {
        relativePath: bookingPageUrls.path,
        url: bookingPageUrls.base + bookingPageUrls.path,
      },
    };
  }
  if (calendarPageUrls) {
    calendarPage = {
      calendarPage: {
        relativePath: calendarPageUrls.path,
        url: calendarPageUrls.base + calendarPageUrls.path,
      },
    };
  }
  return {
    ...servicePage,
    ...bookingPage,
    ...calendarPage,
  };
}

export const mapServiceToServiceV2 = ({
  service,
  serviceResponse,
}: {
  service: CatalogServiceDto;
  serviceResponse?: GetServiceResponse;
}): Service => ({
  id: service.id,
  name: service.info.name,
  description: service.info.description,
  tagLine: service.info.tagLine,
  type: mapServiceTypeV1ToV2(service.type),
  sortOrder: service.order,
  category: service.info.category,
  payment: mapPaymentToPaymentV2(service.payment, service.pricingPlanInfo),
  locations: mapLocationsToLocationV2(service.info.locations),
  schedule: mapScheduleToScheduleV2(service.scheduleHeader, serviceResponse),
  mainSlug: { name: service.urlName },
  onlineBooking: mapServiceOnlineBookingToV2(service),
  media: mapServiceMedia(service.info.media),
  urls: mapServicesUrls(serviceResponse!),
});
