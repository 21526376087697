import {
  CatalogServiceDto,
  ConferencePlatform,
} from '@wix/bookings-uou-types/dist/src';

export interface TitleAndTaglineViewModel {
  title: string;
  tagline?: string;
  onlineProvider?: ConferencePlatform;
  availableOnline?: boolean;
}

export const titleAndTaglineViewModelFactory = (
  serviceDto: CatalogServiceDto,
): TitleAndTaglineViewModel => {
  const viewModel: TitleAndTaglineViewModel = {
    title: serviceDto.info?.name,
    tagline: serviceDto.info?.tagLine,
  };
  viewModel.availableOnline =
    !!serviceDto.schedulePolicy?.includeConferenceOption;
  return viewModel;
};
