import {
  ServiceInfoDto,
  ServiceLocationType,
} from '@wix/bookings-uou-types/dist/src';
import { LocationMapper } from '../../mappers/location-deprecated.mapper';

export interface SchedulingLocationViewModel {
  currentLocation?: string;
  locationOptions?: LocationDropdownOption[];
}

export interface LocationDropdownOption {
  id: string;
  value: string;
}

export const All_LOCATIONS_ID = '';

export const schedulingLocationViewModelFactory = ({
  serviceInfoDto,
  selectedLocation,
  t,
}: {
  serviceInfoDto: ServiceInfoDto;
  selectedLocation?: string;
  t: (key: string) => string;
}): SchedulingLocationViewModel => {
  let locationOptions: LocationDropdownOption[] = [];
  if (serviceInfoDto.locations && serviceInfoDto.locations.length > 1) {
    const locationMapper = new LocationMapper('');
    locationOptions = serviceInfoDto.locations
      .filter(
        (serviceLocation) =>
          serviceLocation.type === ServiceLocationType.OWNER_BUSINESS,
      )
      .map((serviceLocation) => ({
        id: serviceLocation?.businessLocation?.id || '',
        value: locationMapper.text({
          serviceLocation,
          useBusinessName: true,
        }),
      }));
  }

  let currentLocation;
  if (selectedLocation) {
    currentLocation = selectedLocation;
  } else {
    currentLocation = '';
  }

  if (locationOptions.length < 2) {
    currentLocation = undefined;
  } else {
    locationOptions.splice(0, 0, {
      id: All_LOCATIONS_ID,
      value: t('All Locations'),
    });
  }

  return {
    currentLocation,
    locationOptions,
  };
};
